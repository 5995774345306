var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.getSessionMembers,
      pagination: false
    },
    scopedSlots: _vm._u([
      {
        key: "startTimeRender",
        fn: function(text) {
          return _c("div", {}, [_vm._v("\n    " + _vm._s(text) + "\n  ")])
        }
      },
      {
        key: "handlers",
        fn: function(text, record) {
          return _c(
            "div",
            { staticClass: "gx-text-right" },
            [
              record.schedule && record.schedule.id
                ? _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-success gx-mb-0",
                      attrs: { icon: "check" },
                      on: {
                        click: function($event) {
                          return _vm.removeMemberAttendance(record)
                        }
                      }
                    },
                    [_vm._v("\n      Present\n    ")]
                  )
                : _c(
                    "a-button",
                    {
                      staticClass: "gx-btn-danger gx-mb-0",
                      on: {
                        click: function($event) {
                          return _vm.createNewSchedule(record)
                        }
                      }
                    },
                    [_vm._v("\n      Absent\n    ")]
                  )
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }